@import "https://fonts.googleapis.com/css2?family=Tiro+Gurmukhi&display=swap";
html, body{
  background-color: rgb(241, 221, 184);
  padding: 1vw;
  margin: 0;
  text-align: left;
  font-family: 'Tiro Gurmukhi', serif;
}

/***************************************************************************************************
 * HEADER
 **************************************************************************************************/

  .heading{
    font-size: 13vh;
    line-height: 13vh;
    margin: 0;
  }

  .text{
    font-size: 6vh;
    text-align: right;
    margin: 0;
    line-height: 6vh;
    font-weight: bolder;
  }

  .text a{
    color: rgb(167, 85, 3);
  }

  .text b{
    color: rgb(255, 106, 0);
    font-weight: bolder;
  }

  .highlight{
    color: rgb(167, 85, 3);
  }

  .extra-info{
    color: rgb(255, 106, 0);
    font-size: 4vh;
    line-height: 5vh;
  }


/***************************************************************************************************
 * SUBSCRIPTION FORMS
 **************************************************************************************************/
  .overlay-class{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(167, 85, 3, 0.5);
  }

  .content-class{
    width: 50vw;
    position: absolute;
    overflow: auto;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    outline: none;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0;
    border-style: none;
    background: rgb(255, 246, 228);
    box-shadow: 0 0 20px 1px rgb(106, 64, 0);
    padding: 30px;
  }

  .close-btn{
    position: fixed;
    top: 20px;
    right: 16px;
    font-size: 30px;
    padding: 0px 9px 0px 9px;
    border-radius: 30px;
    color: white;
    background-color:rgb(255, 106, 0);
    box-shadow: 0px 0px 10px 2px white;
    transition: 0.3s ease;
    cursor: pointer;
  }

  .close-btn:hover{
    background-color: rgb(171, 71, 0);
    transition: 0.3s ease;
  }
 
 .logo-image{
    position: fixed;
    top: 0px;
    right: 0px;
    width: 20vw;
    background-color: rgba(255, 255, 255, 0.75);
    padding: 10px;
  }

  .form-head{
    font-size: 7.5vh;
    line-height: 7.5vh;
    width: 100%;
    margin: 0;
    margin-top: 40px;
  }

  .subscription-options{
    margin: 0;
    text-align: center;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    margin-left: auto;
    margin-right: auto;
  }

  .options{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: auto;
  }

  .tile{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
    padding: 30px;
    row-gap: 10px;
    margin: 2vw;
    background-color: rgba(255, 255, 255, 0.525);
    box-shadow:  0 0 20px 1px rgb(106, 64, 0);
    max-width: 35vw;
    min-width: auto;
    min-height: auto;
    max-height: auto;
  }

  .subscription-form {
    display: flex;
    flex-flow: column;
    width: 100%;
    gap: 20px;
    font-size: 3vh;
  }
  
  .subscription-form label {
    display: flex;
    flex-flow: row;
    font-size: 3vh;
    width: 100%;
    gap: 14px;
  }
  
  .subscription-form label span {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    font-weight: bolder;
    width: 30%;
    text-align: right;
  }
  
  .subscription-form select {
    width: 100%;
    border-radius: 0;
    border-style: none;
    padding: 5px;
    font-size: 3vh;
    font-family: 'Tiro Gurmukhi', serif;
    line-height: 3vh;
    color: rgb(255, 106, 0);
    box-shadow: 0 0 8px 1px rgb(106, 64, 0);
  }
  
  .subscription-form select:focus {
    box-shadow: 0 0 8px 1px rgb(255, 106, 0);
    outline: none;
  }

  .option-name{
    font-size: 5vh;
    align-self: center;
  }

  .option-price-sem{
    font-size: 3vh;
  }

  .option-price-sem i{
    font-weight: bolder;
    font-size: 4vh;
  }

  .link{
    word-break: break-all;
  }

  .subscribe-btn{
    margin: auto;
    margin-top: 30px;
    font-size: 3.5vh;
    line-height: 4vh;
    width: fit-content;
    color: white;
    font-weight: bolder;
    letter-spacing: 1px;
    background-color: #a75503;
    padding: 15px;
    cursor: pointer;
    transition: ease 0.5s;
  }

  .subscribe-btn:hover{
    background-color: rgb(255, 106, 0);
    transition: ease 0.5s;
  }

/***************************************************************************************************
 * FORM
 **************************************************************************************************/

form{
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 20px;
  font-size: 3vh;
}

input {
  transition-property: background-color;
  transition-duration: 0.2s;
  font-family: 'Tiro Gurmukhi', serif;
}

form label{
  display: flex;
  flex-flow: row;
  font-size: 3vh;
  width: 100%;
  gap: 14px;
}

form label span{
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  font-weight: bolder;
  width: 30%;
  text-align: right;
}

input[type='text'] {
  width: 100%;
  border-radius: 0;
  border-style: none;
  padding: 5px;
  font-size: 3vh;
  line-height: 3vh;
  color: rgb(255, 106, 0);
  box-shadow:  0 0 8px 1px rgb(106, 64, 0);  
}

input[type='text']:focus {
  box-shadow:  0 0 8px 1px rgb(255, 106, 0);
  outline: none;
}

.print-dates{
  text-align: right;
  float: right;
  width: 50vw;
  margin-bottom: 25px;
}

.date{
  white-space: nowrap;
  margin-right: 5px;
}

.subscribe{
  align-self: center;
  width: fit-content;
  font-family: 'Tiro Gurmukhi', serif;
  font-size: 4.5vh;
  padding: 10px;
  border-style: none;
  box-shadow:  0 0 8px 1px rgb(106, 64, 0);
  background-color: rgb(255, 106, 0);
  font-weight: bolder;
  color: white;
  margin-top: 20px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: ease 0.3s;
}

.subscribe:hover{
  background-color: rgb(106, 64, 0);
  transition: ease 0.3s;
}

.subscribe:focus{
  background-color: black;
}

.subscribe-title{
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 5vh;
  margin-bottom: 30px;
}

.deliver-options{
  width: 103%;
  outline: none;
  font-size: 3vh;
  line-height: 3vh;
  height: 5.5vh;
  font-family: 'Tiro Gurmukhi', serif;
  border: none;
  box-shadow:  0 0 8px 1px rgb(106, 64, 0);
}

.deliver-options:focus{
  box-shadow:  0 0 8px 1px rgb(255, 106, 0);
}

.price{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: 4px;
  box-shadow:  0 0 8px 1px rgb(106, 64, 0);
}

table{
  width: 100%;
  text-align: center;
  font-size: 4vh;
}

.link{
  color: rgb(255, 106, 0);
  transition: ease 0.5s;
}

.link:hover{
  color: rgb(167, 85, 3);
  transition: ease 0.5s;
}

/***************************************************************************************************
 * CALLBACK
 **************************************************************************************************/

 #callback-status {
  text-align: left;
  margin: 15px;
  font-size: 18px;
  font-weight: 300;
}

.green {
  color: #36c993;
}

.red {
  color: #f25244;
}

@media screen and (max-width: 800px){
  .price{
    width: 100%;
  }

  .content-class{
    width: 78vw;
  }
}


@media screen and (max-width: 940px){
  .link{
    word-break: break-all;
  }
}

@media screen and (max-width: 700px){
  .content-class{
    width: 80vw;
    top: 20px;
    left: 10px;
    right: 10px;
    bottom: 20px;
  }

  .price{
    width: 100%;
  }
}

@media screen and (max-width: 660px){
  .tile{
    min-width: 70vw;
  }

  .heading{
    font-size: 13vw;
    line-height: 13vw;
  }

  .text{
    font-size: 5vw;
    line-height: 5vw;
  }

  .extra-info{
    font-size: 4vw;
    line-height: 4vw;
  }

  .form-head{
    font-size: 10vw;
    line-height: 10vw;
  }
}

@media screen and (max-width: 900px){
  .close-btn{
    visibility: visible;
    top: 10px;
    right: 6px;
    font-size: 20px;
    padding: 0px 6px 0px 6px;
    border-radius: 30px;
  }
}

@media screen and (max-width: 450px){
  form label{
    flex-flow: row wrap;
  }
  form label span{
    width: 100%;
    text-align: left;
  }

  table{
    font-size: 5vw;
  }
}

@media screen and (max-width: 400px){
  .content-class{
    width: 76vw;
    height: calc(100% - 100px);
    top: 20px;
    left: 0px;
    right: 0px;
    bottom: 20px; 
  }

  .print-dates{
    text-align: center;
    width: 100%;
    margin-bottom: 25px;
  }

  .logo-image{
    position: static;
    width: 60vw;
    background: none;
    margin-bottom: 30px;
  }

  .welcome-tile{
    text-align: center;

  }
  .heading{
    font-size: 15vw;
    line-height: 15vw;
  }

  .text{
    text-align: center;
    font-size: 7vw;
    line-height: 7vw;
  }

  .print-dates{
    line-height: 8vw;
  }

  .extra-info{
    font-size: 6vw;
    line-height: 6vw;
  }

  .form-head{
    font-size: 13vw;
    line-height: 13vw;
  }
}

@media screen and (max-width: 300px){
  .content-class{
    width: 75vw;
    height: 83%;
  }
}